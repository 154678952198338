<div *ngIf="!loader">
   <div class="productBox">
      <div class="sku_tags outofstock" *ngIf="productData.qty === 0">
         <p class="instock text-danger m-0"><span></span>OUT OF STOCK</p>
      </div>

      <div [routerLink]="['/product/',productData.slug]" class="productImg">
         <img [src]="productData.master_image" alt="{{productData.title}}" />
      </div>

      <div class="productDetails">
         <div class="proTitles">
            <h4 class="proHead">{{productData.title}}</h4>
            <div class="mainPrice">
               <p *ngIf="productData.offer_price!=productData.main_price" class="offer_price">
                <span>₹</span>{{productData.main_price}}
               </p>

               <p><span>₹</span>{{productData.offer_price}}</p>
            </div>
         </div>

         <div class="productBtns_groups">
            <div class="qty-box">
               <h5>Qty.</h5>
               <div class="qtyInput-group">
                  <button type="button" class="btn quantity-left-minus" data-type="minus" (click)="decrement()">
                     <i class="material-icons">remove</i>
                  </button>
                  
                  <input type="text" name="quantity" class="form-control input-number" [value]="counter" disabled />
                  
                  <button type="button" class="btn quantity-right-plus" data-type="plus" (click)="increment()">
                     <i class="material-icons">add</i>
                  </button>
               </div>
            </div>

            <button type="submit" class="btn ThemeBtn ThemeBtn-small addCart-btn" (click)="taxCalculate(productData);addtocart(productData);" [disabled]="productData.qty === 0">Add to cart</button>
            
            <button type="submit" class="btn ThemeBtn ThemeBtn-small wishList-btn bg-secondry" (click)="addtowish(productData)">Wishlist</button>
         </div>
      </div>
   </div>
</div>

<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>