import { Component, Input,Output,OnInit, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';


@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  @Input() section: any[] =[];
  @Input() copySection:any[]=[];
  @Input() categories: any[] = [];
  //public products: Product[] = [];
  public collapse: boolean = true;
  @Output() categoryFilter  : EventEmitter<any> = new EventEmitter<any>();
  showSearch: boolean=false;
  public copySectionPro:any;
  sections :any[]=[];
  selectedCat: any;
  constructor(public productService: ProductService) { 
    //this.productService.getProducts.subscribe(product => this.products = product);
  }

  ngOnInit(): void {
    this.copySectionPro= [...this.copySection];
  }

  
  showSearchBox(){
    this.showSearch = !this.showSearch
  }

  clearFilter(){
    this.categoryFilter.emit({'category':''})
  }

  addQueryParams(category){
    // this.router.navigate([], { 
    //   relativeTo: this.route,
    //   queryParams: {'category':category.dept_id},
    //   queryParamsHandling: 'merge', // preserve the existing query params in the route
    //   skipLocationChange: false  // do trigger navigation
    // })
    this.categoryFilter.emit({'category':category.dept_id})
  }

  get filterbyCategory() {
    // const category = [...new Set(this.products.map(product => product.type))]
    // return category;
    // var uniqueCategories=[];
    

      // this.products.filter((product) => {
    //   if(product.dept_name){
    //     const index = uniqueCategories.indexOf(product.dept_name)
    //       if (index === -1) uniqueCategories.push(product.dept_name)
    //   }
    // })
    console.log(this.section)
    return this.section[0];
  }

  filterCategory(event){
    if(event.target.value){
      console.log("here",event.target.value)
      if(event.target.value!='' || event.target.value!=undefined){
        //console.log("here______________",this.copySection);
        this.section[0] = this.section[0].filter(
          item => item.dept_name.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1
        )
      }else{
        console.log("here______________",this.copySectionPro);
        this.section=JSON.parse(localStorage.getItem('catFilter'))
      }
    }else{
      console.log("here______________",this.copySectionPro)
      this.section=JSON.parse(localStorage.getItem('catFilter'))
    }
  }

  appliedFilter(event) {
    // let index =console.log(event.target.value);  // checked and unchecked value
    // if (event.target.checked){
    //   this.selectedCat = event.target.value;
    //   this.categoryFilter.emit({'category':event.target.value}); // push in array checked value
    // }else {
    //   this.selectedCat=''
    //   this.categoryFilter.emit({'category':''})  // removed in array unchecked value  
    // }

    let index = this.categories.indexOf(event.target.value);  // checked and unchecked value
    if (event.target.checked)   
      this.categories.push(event.target.value); // push in array cheked value
    else 
      this.categories.splice(index,1);  // removed in array unchecked value  
    
    let categories = this.categories.length ? { category: this.categories.join(",") } : { category: null };
    this.categoryFilter.emit(categories);
      
  }

  checked(range){
    if(this.categories.indexOf(range) != -1){
      return true;
    }
    return false;
  }

}
