import { Component, OnInit } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})

export class LeftMenuComponent implements OnInit {

  public menuItems: Menu[];
  categories: any = [];

  constructor(private router: Router, public navServices: NavService) {
    // this.navServices.getMenus().subscribe(res=>{
    //   console.log(res['data'])
    //   let data = res['data'];
    //   data.forEach(element => {
    //     console.log(element.division.division_name)
    //   });

    // })

    // let menuArr=[];
    // menuArr.push({title: 'Home',path:"/home", type: 'link', active: false});
    // menuArr.push({
    //   title: 'KURTA SETS', type: 'link', active: false,path:'/shop/collection/department/4/kurta-sets'
    // },
    // {
    //   title:'KURTIES',type:'link', active: false,path:'/shop/collection/department/4/kurtas-kurties'
    // }
    // )

    //this.menuItems = menuArr

    //this.navServices.leftMenuItems.subscribe(menuItems => this.menuItems = menuItems );
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }
  getDataByCat(cat){
    this.router.navigateByUrl(`/producr/category/${cat.slug}`)
    
    }

  ngOnInit(): void {
    this.categories = JSON.parse(localStorage.getItem('categories'))
  }

  leftMenuToggle(): void {
    this.navServices.leftMenuToggle = !this.navServices.leftMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

}
