import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ProductService } from './../../../shared/services/product.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-header-four',
  templateUrl: './header-four.component.html',
  styleUrls: ['./header-four.component.scss']
})
export class HeaderFourComponent implements OnInit {

  @Input() class: string = 'header-2 header-6';
  @Input() themeLogo: string = 'assets/images/logos/mithasLogo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = true; // Default false
  suggestions: any = []
  adsbanner: any = []
  public stick: boolean = false;
  searchTerm: FormControl = new FormControl();
  showOptions: boolean = true;
  text: any;
  constructor(private router: Router, private productService: ProductService) { }

  clearSuggestions() {
    // this.showOptions = false;
  }

  ngOnInit(): void {
    this.searchTerm.valueChanges.subscribe(
      term => {
        if (term != '' && term.length > 2) {
          let obj = {
            "keyword": term
          }
          this.productService.searchItems(obj).subscribe(
            res => {
              this.suggestions = res['data'] as any[];
              //console.log(data[0].BookName);
          })
        }
      })

      this.productService.getdeliverytext().subscribe(res=> this.text = res['data']['marquee_text'])

      this.productService.getShippingBanner().subscribe(
        res => {
          this.adsbanner = res['data'];
          //console.log(res['data']);
      })
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    //console.log(number,window.innerWidth);
    if (number >= 10 && window.innerWidth > 10) {
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

  @HostListener('document:click', ['$event']) clickout(event) {
    // console.log(event)
    if (event && event.target && this.suggestions && this.suggestions.length > 0) {
      this.suggestions = [];
    }
  }

  search($event) {
    console.log("event___", $event)
    if ($event.target.value != '') {
      this.router.navigateByUrl('/shop/collection/search/' + $event.target.value)
    }
  }

  selectEvent($event) {
    console.log("event___", $event)
  }

  callFunc(suggestion) {
    //alert("called")
    // this.searchTerm.setValue(suggestion.itemTitle);
    // setTimeout(() => {
    //   this.suggestions=[];  
    // }, 1000);


    this.router.navigateByUrl('/shop/product/' + suggestion.parentItemSlug)
  }

  showSuggestions($event) {
    if ($event.target.value.length > 3) {
      let obj = {
        "keyword": $event.target.value
      }
      this.productService.searchItems(obj).subscribe(res => {
        console.log(res);
        this.showOptions = true;
        this.suggestions = res['data']
      }, error => {
        this.suggestions = [];
      })
    } else {

    }
  }

}
