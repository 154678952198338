import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { ProductService } from "../../../shared/services/product.service";
import { Product } from '../../../shared/classes/product';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-collection-no-sidebar',
  templateUrl: './collection-no-sidebar.component.html',
  styleUrls: ['./collection-no-sidebar.component.scss']
})
export class CollectionNoSidebarComponent implements OnInit {

  public grid: string = 'col-xl-3 col-md-3 col-sm-3 col-6';
  public layoutView: string = 'grid-view';
  public products: any[] = [];
  public pageNo: number = 1;
  public paginate: any = {}; // Pagination use only
  public sortBy: string; // Sorting Order
  public loader: boolean = true;
  public division:any;
  public sectionId:any;
  selectedSort:any;
  productLoaded:boolean=false
  breadCrumb: any;
  catname: any;
  id: any;
  uniqueCategories: any[]=[];
  breadCrumbDetails: any;
  productsSize: any[]=[];
  priceMax: number;
  priceMin: number;

  

  constructor(private route: ActivatedRoute, private router: Router,
    private title:Title,private meta:Meta,private viewScroller: ViewportScroller, public productService: ProductService) {   
  }


  ngOnInit(): void {
    this.getMetaTags();

    let obj = {
      "perpage":"50",
      "page":"1"
    }

    this.productService.getAllProducts(obj).subscribe(res=>{
      console.log("all-products"+res);
      this.products = res['data'];
    },error=>{
      this.productLoaded = true;
      this.products = [];
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor('products');
    })

    this.route.params.subscribe(params => {
      //console.log(params)
      this.breadCrumb = params.slug.split('-').join(" ")
      this.getBreadCrumbs(params.slug)
   

      
    })
  }

  getBreadCrumbs(slug) {
    let obj = {
      "type":"department",
      "slug":slug
    }
    this.productService.getBreadCrumbs(obj).subscribe(res=>{
      this.breadCrumbDetails = res['data']
      console.log(res)
    })
  }

  getMetaTags(){
    let obj:any = {};
    obj.url = window.location.href;
    this.productService.seoSettingData(obj).subscribe((res:any)=>{
      this.title.setTitle(res.data.title);
      this.meta.addTag({ name: 'description', content: res.data.description });
      this.meta.addTag({ name: 'keyword', content: res.data.keyword })
    });
  }

}
