<div class="icon-nav">
   <ul class="main_ul">
      <li class="mobile-search search-widgets" id="search-widgets">
         <div class="searchIcon" (click)="toggleShow()">
            <img src="assets/images/icon/searchNav.svg" class="img-fluid" alt="">
         </div>

         <div class="SearchBar" *ngIf="isShown" id="divshow">
            <input placeholder="Search Here...." type="search" [(ngModel)]="placeId"
               (keyup.enter)="Searchbutton(placeId)" class="customsaech" />
            <button class="CustomSaechBTn" (click)="Searchbutton(placeId)">
               <img src="assets/images/icon/searchNav.svg" class="img-fluid" alt="">
            </button>
         </div>
      </li>

      <li *ngIf="!isLoggedIn" class="onhover-div mobile-users">
         <a routerLink="pages/login">
            <img src="assets/images/icon/userNav.svg" class="img-fluid" alt="">
         </a>
      </li>

      <li *ngIf="isLoggedIn" class="onhover-div mobile-users">
         <a routerLink="pages/profile" class="User-Profile">
            <img src="assets/images/icon/userNav.svg" class="img-fluid" alt="">
            <p class="UserName">{{userName}}</p>
         </a>

         <ul *ngIf="isLoggedIn" class="show-div UserProfile-menus">
            <li>
               <a routerLink='/pages/orders'>
                  <i class="fa fa-cart-arrow-down" aria-hidden="true"></i>
                  <span>Your Orders</span>
               </a>
            </li>
            <li>
               <a routerLink="/pages/profile">
                  <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                  <span> Your Account</span>
               </a>
            </li>
            <li>
               <a routerLink="/pages/contact">
                  <i class="fa fa-phone-square" aria-hidden="true"></i>
                  <span>Contact us</span>
               </a>
            </li>
            <li>
               <a href="javascript:void(0);" (click)="logout()">
                  <i class="fa fa-sign-out" aria-hidden="true"></i>
                  <span>Logout</span>
               </a>
            </li>
         </ul>
      </li>

      <li class="onhover-div mobile-cart">
         <div class="cartBox">
            <a href="javascript:void(0);">
               <img src="assets/images/icon/cartNav.svg" class="img-fluid blocks" alt="">
            </a>
            <span class="cart_qty_cls" *ngIf="products">{{ products?.length }}</span>

            <span class="cart_qty_cls" *ngIf="!products">0</span>
         </div>


         <ul class="show-div shopping-cart" *ngIf='!products?.length'>
            <h5 class="empty-alert">Your cart is currently empty.</h5>
         </ul>

         <div class="show-div shopping-cart" *ngIf='products?.length > 0'>
            <ul class="">
               <li *ngFor="let product of products;let i=index">
                  <div class="media">
                     <a>
                        <img class="mr-3 custom_size_pros_img" [src]="product.master_image">
                     </a>
                     <div class="media-body custom_cats">
                        <a *ngIf="isLoggedIn">
                           <h5>{{ product.product_name }}</h5>
                        </a>
                        <a *ngIf="!isLoggedIn">
                           <h5>{{ product.title }}</h5>
                        </a>
                        <h4 *ngIf="isLoggedIn">
                           <span>
                              {{ product.product_qty }} x
                              Rs {{ product.product_price}}
                           </span>
                        </h4>
                        <h4 *ngIf="!isLoggedIn">
                           <span>
                              {{ product.qty }} x
                              Rs {{ product.price}}
                           </span>
                        </h4>

                     </div>
                  </div>
                  <div class="close-circle" (click)="removeItem(product,i)">
                     <a href="javascript:void(0)"><i class="fa fa-times" aria-hidden="true"></i></a>
                  </div>
               </li>
               <li>
                  <div class="total">
                     <h5>Subtotal : <span>Rs {{sum.toFixed(2)}}</span></h5>
                  </div>
               </li>
            </ul>

            <div class="buttons catrs_hma">
               <a [routerLink]="['/shop/cart']" (click)="redirect()" class="view-cart">view cart and checkout</a>
            </div>
         </div>
      </li>
   </ul>
</div>