import { Component, OnInit, Input, ViewChild, PLATFORM_ID, Inject } from '@angular/core';
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import { isPlatformBrowser } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Component({
  selector: 'app-product-box-one',
  templateUrl: './product-box-one.component.html',
  styleUrls: ['./product-box-one.component.scss']
})
export class ProductBoxOneComponent implements OnInit {

  @Input() product: any;
  @Input() productData: any;
  @Input() currency: any = this.productService.Currency; // Default Currency 
  @Input() thumbnail: boolean = false; // Default False 
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;

  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;



  public ImageSrc: string;
  public isWishlisted: boolean = false;
  public discounted: any
  public amount_diff: any
  public diff_percent: any
  public counter: number = 1;
  productFeild: any = '';
  userData: any = [];
  productcart = [];
  cartdata: any = [];
  taxdata: any = [];


  constructor(@Inject(PLATFORM_ID) private platformId: Object, private router: Router, private toastrService: ToastrService, private productService: ProductService) { }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem("userinfo"));

    if (this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
  }
  addtowish(product) {
    if (localStorage.isLoggedIn) {
      let obj = {
        "user_id": this.userData.id,
        "product_id": product.pro_id
      }
      this.productService.addtowishlist(obj).subscribe(res => {
        if (res) {
          this.toastrService.success(res['message']);
          // console.log(res)
        }
      })
    }
    else {
      this.toastrService.error("Please login first for add to wishlist..")
    }
  }

  increment() {
    this.counter++ ;
  }

  decrement() {
    if (this.counter > 1) this.counter-- ;
    
  }


  taxCalculate(product) {

  }
  addtocart(product) {

    if (localStorage.isLoggedIn) {
      let obj: any = {}
      obj.user_id = this.userData.id
      obj.product_id = product.pro_id
      obj.qty = this.counter
      obj.price = product.offer_price
      obj.weight = product.weight
      obj.weight_type = product.weight_type
      obj.main_price = product.main_price
      obj.variant_sku = product.sku
 //     console.log("dfjhvjsdf", obj)
      this.productService.addtoCart(obj).subscribe((res: any) => {
        // console.log(res)
        this.productService.productAddUpdate$.next('added')
        this.toastrService.success("Item added to a Cart")
        //  this.router.navigateByUrl("/shop/cart")
      })
    }else{
      if(localStorage.getItem('cartdata')){
        this.productcart = JSON.parse(localStorage.getItem('cartdata'))
      }else{
        this.productcart=[]
      }
      var isadded = false;
      var index = 0
      for (var i = 0; i < this.productcart.length; i++) {
        if(product.pro_id  == this.productcart[i].product_id && product.weight == this.productcart[i].weight){ 
          //this.productcart[i].qty = this.productcart[i].qty+1; 
          isadded = true;
          index = i 
          console.log(this.productcart) //add two
            break;  //exit loop since you found the person
        }
      }
      let object = {
        "product_id":product.pro_id,
       // "qty":isadded?1+this.productcart[index].qty:1,
       "qty":isadded?this.counter+this.productcart[index].qty:this.counter,
        "price":product.offer_price
        }
      this.productService.calulatetax(object).subscribe((res:any)=>{
      this.taxdata = res.data
      if(res){
      let obj:any={}
      obj.product_id = product.pro_id
      obj.qty = object.qty
      obj.price = product.offer_price
      obj.title = product.title
      obj.master_image = product.master_image
      obj.slug = product.slug
      obj.main_price=product.main_price
      obj.gst_value =  this.taxdata.gst_value
      obj.cgst_value=  this.taxdata.cgst_value
      obj.sgst_value =  this.taxdata.sgst_value
      obj.taxamount =  this.taxdata.taxamount
      obj.total_amt = this.taxdata.total_amt
      obj.weight = product.weight
      obj.weight_type = product.weight_type
      console.log("dfjhvjsdf",obj)

      
      if(!isadded){
        this.productcart.push(obj)
      }else{
        this.productcart[index] = obj;
      }
      console.log("productcart",this.productcart)
       localStorage.setItem("cartdata", JSON.stringify(this.productcart))
       
       this.productService.productAddUpdate$.next('added')
       this.productService.refreshData.next()
   
       this.toastrService.success("Item added to a Carts")
      //  this.router.navigateByUrl("/shop/cart")
      }
      })
    }

  }
  // Get Product Color
  Color(variants) {
    const uniqColor = [];
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color)
      }
    }
    return uniqColor
  }

  // Change Variants
  ChangeVariants(color, product) {
    product.variants.map((item) => {
      if (item.color === color) {
        product.images.map((img) => {
          if (img.image_id === item.image_id) {
            this.ImageSrc = img.src;
          }
        })
      }
    })
  }

  // Change Variants Image
  ChangeVariantsImage(src) {
    this.ImageSrc = src;
  }

  addToCart(product: any) {
    //this.productService.addToItemCart(product);
  }

  // addToWishlist(product: any) {
  //   if (isPlatformBrowser(this.platformId)) {
  //     if(localStorage.isLoggedIn){
  //       console.log("router",this.router.url)
  //       console.log(product,product.itemvariants_id);
  //       let obj = {
  //         "user_id":localStorage.customerId,
  //         "itemvariants_id":product.itemparentId
  //       }
  //       this.productService.addProToWishlist(obj).subscribe(res=>{
  //         console.log(res);
  //         this.productService.listWishlist({"user_id":localStorage.customerId}).subscribe(res=>{
  //           console.log(res);
  //           if(res['data'].item_ids){
  //             localStorage.setItem("wishlisted",res['data'].item_ids);
  //              var items = res['data'].item_ids.split(',');
  //              console.log(items)
  //              if(items.includes(this.product.itemparentId.toString())){
  //               this.isWishlisted = true;
  //              }else{
  //                 this.isWishlisted = false;
  //              }
  //           }
  //           this.productService.wishLishtUpdated$.next(1)
  //         })
  //        // this.isWishlisted
  //         this.toastrService.success(res['message']);
  //       });
  //     }else{
  //       localStorage.setItem("redirectBack",this.router.url)
  //       this.router.navigateByUrl('/pages/login');  
  //     }
  //   }
  // }

  // addToCompare(product: any) {
  //   this.productService.addToCompare(product);
  // }

}
