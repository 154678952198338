import { Component, OnInit } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public menuItems: Menu[];
  public category: any = [];
  public subCategory: any = [];
  productdata: any;

  constructor(private router: Router, public route: ActivatedRoute, public navServices: NavService, public productService: ProductService) {
    //this.navServices.items.subscribe(menuItems => this.menuItems = menuItems );
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });


  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.productService.getCategory().subscribe(async (res: any) => {
        // console.log("category",res)
        this.category = res.data
        for (var [index1, value] of this.category.entries()) {
          // console.log(index1,value)
          await this.productService.getSubCategory(value.id).subscribe(res => {
            if (res) {
              if (res['data'].length > 0) {

                let id = parseInt(res['data'][0].cat_id);
                let index = this.category.findIndex(x => x.id === id);
                // console.log(id);
                if (index >= 0) {
                  this.category[index]['subCategory'] = res['data'];
                  // console.log(this.category)
                }

              }

              //this.lagacy = res['data'];
            }
          })
          //   console.log("cateogyr"+this.category[index1].id); 

        }
        // console.log(this.category)
        localStorage.setItem("subCategory", JSON.stringify(this.subCategory))

        localStorage.setItem("categories", JSON.stringify(this.category))
      })
    })
  }


  getDataByCat(cat){
    this.router.navigateByUrl(`/product/category/${cat.slug}`)
    }
  
    getDataBySubCat(sub_cat_id,slug){
    //  alert(sub_cat_id+slug);
      this.router.navigateByUrl(`/product/sub-category/${slug}`)
      }

  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

}
