<div *ngIf="!loader">
    <div class="img-wrapper">
      
      <div class="front coalle">
        <a class="add-list" href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">
          <i class="fa fa-heart-o" *ngIf="!isWishlisted" aria-hidden="true"></i>
          <i class="fa fa-heart" *ngIf="isWishlisted" aria-hidden="true"></i>
        </a>
        <a [routerLink]="['/shop/product/',product.parentItemSlug]">
          <img class="img-fluid"
            [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/fashion/1.jpg'" 
            [lazyLoad]="ImageSrc ? ImageSrc : 'assets/images/product/fashion/1.jpg'" 
           />
        </a>
      </div>
   
    </div>
    <div class="product-detail">
      <div class="desujs">
      
        <div class="rpo_de">
          <h6>{{ product?.itemTitle | slice:0:35 }}...</h6>
        <p>{{ product?.description }}</p>
        <h4>Rs {{product.priceInclusive}} <del ><span class="money">Rs {{ product.MRP}}</span></del>
        </h4>
        </div>
        <div class="rattingd">
          {{  discounted  }}% Off
      
        </div>
        
 
      </div>
    </div>
  </div>
  <app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
  