<app-breadcrumb [title]="''" [catname]="catname" [id]="id"></app-breadcrumb>
<!-- section start -->
<section class="mt-4 mt-md-5 mb-md-1 pt-0">
    <div class="container">
        <div class="row">
            <!-- <div class="col-lg-3 col-12"></div> -->
            <div class="col-lg-12 col-12">
                <div class="Products-Titles mb-3">
                    <span >
                        <h2 >All Products</h2>
                    </span>
                    <p>A Perfect tea time snack & a delight to munch on!</p>
                </div>
            </div>
        </div>
        <div class="row justify-content-end">
          
            <!-- <div class="col-xl-3 col-lg-6 col-md-6 col-12" *ngIf="!message">
                <div class="sort-sortBy _custom">Sort by : {{selectedSort}}<span></span> <i class="fa fa-sort-down"></i>
                    <ul class="sort-list">
                        <li (click)="updateFilter({'sortBy':'latest'});selectedSort='Whats New'">
                            <label class="sort-label "><input type="radio" value="new">What's New</label>
                        </li>
                        <li (click)="updateFilter({'sortBy':'DESC'});selectedSort='Price: High to Low'"><label
                                class="sort-label "><input type="radio" value="price_desc">Price: High to Low</label>
                        </li>
                        <li (click)="updateFilter({'sortBy':'ASC'});selectedSort='Price: Low to High'"><label
                                class="sort-label "><input type="radio" value="price_asc">Price: Low to High</label>
                        </li>
                    </ul>
                </div>
            </div> -->
        </div>
    </div>
</section>
<section class="mt-1 pt-0 border-top_in">
    <div class="collection-wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="collection-content col">
                    <div class="page-main-content">
                        <div class="row">
                            <div class="col-sm-12">
                                
                                <div class="collection-product-wrapper pt-3" id="products">
                                    <!-- <app-grid [products]="products" [paginate]="paginate" [layoutView]="layoutView" [sortBy]="sortBy" (setGrid)="updateGridLayout($event)" (setLayout)="updateLayoutView($event)" (sortedBy)="sortByFilter($event)">
                                    </app-grid> -->
                                    <div class="product-wrapper-grid" [ngClass]="layoutView">
                                        <div class="row">
                                            <div class="col-grid-box" [ngClass]="grid" *ngFor="let product of products">
                                                <div class="product-box">
                                                    <app-product-box-one [product]="product" [productData]="product" [currency]="productService?.Currency" [thumbnail]="false" [cartModal]="true" [loader]="true">
                                                    </app-product-box-one>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 text-center section-b-space mt-5 no-found" *ngIf="productLoaded && products.length == 0">
                                                <img src="assets/images/empty-search.jpg" class="img-fluid mb-4">
                                                <h3>Sorry! Couldn't find the product you were looking For!!! </h3>
                                                <p>Please check if you have misspelt something or try searching with other words.</p>
                                                <a [routerLink]="['/']" class="btn btn-solid">continue shopping</a>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <app-pagination [products]="products" [paginate]="paginate" (setPage)="setPage($event)">
                                    </app-pagination> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- section End -->