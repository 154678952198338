<!-- breadcrumb start -->
<div class="breadcrumb-section">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="page-title">
          <h2>{{ catname }}</h2>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- breadcrumb End -->