<!--modal popup start-->
<ng-template class="theme-modal" #newsletter let-modal>
    <div class="modal-content">
        <div class="modal-body modal7">
            <div class="container-fluid p-0">
                <div class="row">
                    <div class="col-12">
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!--modal popup end-->