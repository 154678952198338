<section class="brand-history-container Fit-Header">
    <div class="container">
        <div class="row mb-md-5 mb-4">
            <div class="col-lg-6 col-md-6 col-12">
                <div class="aboutInfo">
                    <h2 class="infoTitle">The Authenticity of <br> Pure Taste</h2>
                    <p>For more than six and a half decades, Prabhuji (Haldiram) has emerged as a household name for ready to eat snack foods in India. It has come a long way since its humble beginning in 1937 as a small snacks shop in Bikaner, in Rajasthan. The Company delivers a veritable range of ready-to-eat snacks that are more than a fun and efficient way to enjoy healthy and hygienic food. Over the years, the Company has established itself as one of the leading brands in the industry, contributing to the economic and social welfare of the country.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-12">
                <div class="about-imgInfo">
                    <!-- <img src="assets/images/legacy.png"> -->
                </div>
            </div>
        </div>

        <div class="row mb-md-5 mb-4">
            <div class="col-lg-12">
                <div class="aboutInfo">
                    <h2 class="infoTitle">The Story of Pure Taste</h2>
                    <p>Prabhuji is Kolkata’s oldest and most trusted FMCG brand in the business of exotic and ethnic Indian food. It is a name associated with consumers for sweets and namkeens for the past six decades in India and abroad. Known for setting trends, Halidram was the first company in India to introduce branded namkeens. Today the company has diversified into snack food, sweets, syrups, biscuits, and fast food.</p>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="aboutInfo">
                    <h4 class="subTitle">The Company fully owns the following three brands:</h4>
                    <ul class="history-ul">
                        <li>PRABHUJI PURE FOOD (Since-1982, Registered on 12-Aug-2019)</li>
                        <li>PRABHUJI (Since -1980, Registered on 11-Dec-2002)</li>
                        <li>PRABHUJI In House of Haldiram (Since -1980, Applied for registration)</li>
                    </ul>

                    <p>At present the Company is undertaking its sales under the first brand, that is, PRABHUJI PURE FOOD. It is also important to note that this brand is registered in 34 countries worldwide. The Company believes that if required it may use the other two brands in the future as well. The Company commits to duly inform the Ministry in case it plans to undertake usage of the other two brands.</p>
                </div>
            </div>
        </div>

        <div class="row mb-md-5 mb-4">
            <div class="col-lg-12">
                <div class="aboutInfo">
                    <h2 class="infoTitle">Know Our Chairman</h2>
                    <p><strong>Mr. Prabhu Sankar Agarwal</strong> laid the foundation of Prabhuji - a brand name that only honours the legacy of his father but also resonates with the heavenly taste of its offerings. While the people welcomed Prabhuji’s wide range of offerings, the brand attained an iconic status in the food and snack market. Today it has become well-known as a brand that manufactures and sells everything from namkeens to sweets with world class facilities.</p>

                    <p>While<strong> Mr. Prabhu Sankar Agarwal</strong> takes the legacy of his brand forward, he is committed to giving his customers pure unadulterated goodness. With state-of-the-art technology in Food Sourcing, Extruding and Packaging, he ensures that customers get to taste authentic ingredients and heavenly flavours of abhuji. With two modern and efficient manufacturing units in West Bengal, he implements stringent quality control. So that every pack of Prabhuji gives the pure taste of pure food that is purely Indian.</p>

                    <p>Prabhuji, the name itself stems from what we truly believe in, that is, pure and divine. The name Prabhuji, too literally means God, which is pure and divine.</p>

                    <p>We've stuck true to this name since years and have created a legacy of purity which we intend to continue further. We only use the most purest ingredients while making our products. We're proud to call ourselves the one who have become the pioneer of what we, and our name stands for - Purity.</p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div class="aboutInfo">
                    <h2 class="infoTitle">Our Legacy</h2>
                    <p><strong class="subTitle">Prabhuji</strong> - the name that is synonymous to every household. The name that incites pure joy and promises a pure form of taste. From sweets to namkeens to the newly launched ready-to-eat, it has been loved by all age groups across the boundaries, over the years. But how did <strong>Prabhuji</strong> become a household name? Let’s take a walk down memory lane.</p>

                    <p>While<strong> Mr. Prabhu Sankar Agarwal</strong> founded the eponymous brand, his legacy, his penchant for quality food making and his sharp business acumen stems from the house of Haldiram - the popular business family known for their bhujias and other range of snacks.</p>

                    <p>1937 Was the year when his grandfather, <strong>Mr. Ganga Bishen Agarwal</strong>, popularly known as ‘Haldi’ used his expertise of bhujia- making to open a small snack shop in Bikaner, Rajasthan under the name of <strong>Haldiram Bhujiawala.</strong></p>

                    <p>While they had the people enjoying their bhujias, it was time for more. His son <strong>Mr. Rameshwar Lal Agarwal</strong> brought the family to Kolkata for a new beginning. And the third generation of the family, Mr. Prabhu Shankar Agarwal started a small manufacturing unit of sweets and namkeens in the city.</p>

                    <p>While East India was loving the taste of their food, by then brand ‘Haldiram' had become the name of multiple businesses spread across the family tree. And with multiple territories and sub-brands across India, there was no specific entity for the Agarwal family’s delicacies in, Kolkata.</p>
                </div>
            </div>
        </div>
    </div>
</section>