import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductDetailsMainSlider, ProductDetailsThumbSlider } from '../../../../shared/data/slider';
import { Product } from '../../../../shared/classes/product';
import { ProductService } from '../../../../shared/services/product.service';
import { SizeModalComponent } from "../../../../shared/components/modal/size-modal/size-modal.component";
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators } from '@angular/forms';
import { NgxImgZoomService } from 'ngx-img-zoom';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-product-left-sidebar',
  templateUrl: './product-left-sidebar.component.html',
  styleUrls: ['./product-left-sidebar.component.scss']
})
export class ProductLeftSidebarComponent implements OnInit {

  public product: Product = {};
  public counter: number = 1;
  public activeSlide: any = 0;
  public selectedSize: any;
  public mobileSidebar: boolean = false;
  public productImages:any= ['./assets/images/cookie-img01.png','./assets/images/cookie-img02.png','./assets/images/cookie-img03.png','./assets/images/cookie-img04.png','./assets/images/cookie-img05.png']

  @ViewChild("sizeChart") SizeChart: SizeModalComponent;
  
  public ProductDetailsMainSliderConfig: any = ProductDetailsMainSlider;
  public ProductDetailsThumbConfig: any = ProductDetailsThumbSlider;
  productdetails: any=[];
  main_price: any;
  offer_price: any;
  userData: any;
  weight: any;
  qty: any;
  url: any;
  productcart: any=[];
  slug: any;
  catname: any;
  reviewForm: any;
  rate: any=0;
  reviewSubmitted: boolean;
  cartData: any;
  taxdata: any=[];
  weightType: any = "gm";
  product_stock: any;
  enableZoom: Boolean = true;

  constructor(private route: ActivatedRoute, private router: Router,
    private title:Title,private meta:Meta,
    public productService: ProductService,private toastrService: ToastrService,public fb:FormBuilder, private ngxImgZoom: NgxImgZoomService) {
      this.ngxImgZoom.setZoomBreakPoints([{w: 100, h: 100}, {w: 150, h: 150}, {w: 200, h: 200}, {w: 250, h: 250}, {w: 300, h: 300}]);
      this.route.data.subscribe(response => this.product = response.data );
      
    }

  ngOnInit(): void {
    this.slug =this.route.snapshot.params.slug 
    
    

    localStorage.setItem("ordersuccess","notdone")
    this.route.params.subscribe(res=>{
      this.getMetaTags();
      this.getProductDetails()
      
    })
    this.reviewForm = this.fb.group({
      title: ['',[Validators.required]],
      description: ['', [Validators.required]]
    })
  
  }
  get f(){
    return this.reviewForm.controls;
  }

  getMetaTags(){
    let obj:any = {};
    obj.url = window.location.href;
    this.productService.seoSettingData(obj).subscribe((res:any)=>{
      this.title.setTitle(res.data.title);
      this.meta.addTag({ name: 'description', content: res.data.description });
      this.meta.addTag({ name: 'keyword', content: res.data.keyword })
    });
  }

  getProductDetails(){
    let obj:any = {};
    obj.slug = this.route.snapshot.params.slug 
    this.productService.productDetails(obj).subscribe((res:any)=>{
   // this.title.setTitle(res.data.title);
   // this.meta.addTag({ name: 'description', content: res.data.disclaimer })
    this.productdetails = res.data
    console.log(this.productdetails)
    this.main_price = this.productdetails.product_variant[0].main_price
    this.offer_price = this.productdetails.product_variant[0].offer_price
    this.weight = this.productdetails.product_variant[0].weight
    this.product_stock = this.productdetails.product_variant[0].qty
    this.url = `https://prabhujipurefood.com/product/${this.route.snapshot.params.slug}`;
   // console.log(this.product_stock)
    this.catname =  this.productdetails.title
    })
  }
  weightChange(event){

  //console.log("weight change",event.target.value)
  
  
  if(event.target)
  {
    this.weight = this.productdetails.variant.weight[event.target.value]
    this.main_price = this.productdetails.variant.main_price[event.target.value]
    this.offer_price = this.productdetails.variant.offer_price[event.target.value]
    this.weightType =  this.productdetails.variant.weight_type[event.target.value]
    this.product_stock = this.productdetails.variant.qty[event.target.value]



   // console.log(this.productdetails.variant.qty[event.target.value])

   // console.log(this.main_price,this.offer_price)
  //  console.log("weight",this.weight)
  }
 
   
 //console.log("hdfjhvdjh",this.productdetails.variant.main_price[event.target.value])
  }
  // Get Product Color
  Color(variants) {
    const uniqColor = []
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color)
      }
    }
    return uniqColor
  }

  // Get Product Size
  Size(variants) {
    const uniqSize = []
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqSize.indexOf(variants[i].size) === -1 && variants[i].size) {
        uniqSize.push(variants[i].size)
      }
    }
    return uniqSize
  }

  selectSize(size) {
    this.selectedSize = size;
  }
  submitReview(){
    if(localStorage.isLoggedIn){
      if(this.reviewForm.valid && this.rate!=0){
        let obj = {
          "user_id":localStorage.customerId,
          "product_id":this.productdetails.pro_id,
          "rating":this.rate,
          "title":this.reviewForm.value.title,
          "description":this.reviewForm.value.description
        }
        this.productService.submitReview(obj).subscribe(res=>{
       //   console.log(res);
          this.reviewForm.reset();
          this.rate=0;
           this.reviewSubmitted=true;
          this.toastrService.success("Review Submitted Successfully")
        },error=>{
          console.log(error.error.message);
          this.toastrService.error(error.error.message)
        })
      }
      else{
        this.reviewForm.markAllAsTouched()
      }
    }
    else{
      this.toastrService.error("Please Login first for write a review..")
    }
    
  }
  
  // Increament
  // increment(product, qty = 1) {
  //   console.log(product)
  //   this.counter++ ;
  //   // product.variant.qty = product.variant.qty+1;
  //   // this.addtocart(product);
  //  }
  increment() {
    this.counter++ ;
  }

  // Decrement
  // decrement(product, qty = -1) {
  //   if (this.counter > 1) this.counter-- ;
  //   product.variant.qty = product.variant.qty-1;
  //   //this.productService.updateCartQuantity(product, qty);
  //   this.addtocart(product);
  // }
  decrement() {
    if (this.counter > 1) this.counter-- ;
    
  }
  addtowish(product){
    if(localStorage.isLoggedIn){
    let obj = {
      "user_id": localStorage.customerId,
    	"product_id":product.pro_id
    }
   this.productService.addtowishlist(obj).subscribe(res=>{
  if(res){
    this.toastrService.success(res['message'])
    //console.log(res)
  }
   })}
   else{
    this.toastrService.error("Please login first for add to wishlist..")
   }
  }
  addtocart(product){
    if(localStorage.isLoggedIn){
   let obj:any={}
    obj.user_id =  localStorage.customerId
    obj.product_id = product.pro_id
    obj.qty = this.counter
    obj.price =  this.offer_price
    obj.weight = this.weight
    obj.weight_type = this.weightType
    obj.main_price=this.main_price
    //obj.variant_sku=product.sku

  //  console.log("cart info",obj)
    this.productService.addtoCart(obj).subscribe((res:any)=>{
  //   console.log(res)
     this.productService.productAddUpdate$.next('added')
     this.productService.refreshData.next()
     this.toastrService.success("Item added to a Cart")
    //  this.router.navigateByUrl("/shop/cart")
    })
  }
  if(!localStorage.isLoggedIn){
    var isadded = false;
    var index = 0;
    for (var i = 0; i < this.productcart.length; i++) {
      if(product.pro_id  == this.productcart[i].product_id && this.productcart[i].weight == this.weight){ 
        //this.productcart[i].qty = this.productcart[i].qty+this.counter; 
        isadded = true;
        index = i 
      //  console.log(this.productcart) //add two
          break;  //exit loop since you found the person
      }
    }
    let object = {
      "product_id":product.pro_id,
      "qty":isadded?this.counter+this.productcart[index].qty:this.counter,
      "price":this.offer_price
      }
    this.productService.calulatetax(object).subscribe((res:any)=>{
    this.taxdata = res.data
    if(res){
    let obj:any={}
    obj.product_id = product.pro_id
    obj.qty = object.qty
    obj.price = this.offer_price
    obj.title = product.title
    obj.master_image = product?.master_image ? product?.master_image : ''
    obj.slug = product.slug
    obj.main_price=this.main_price
    obj.gst_value =  this.taxdata.gst_value
    obj.cgst_value=  this.taxdata.cgst_value
    obj.sgst_value =  this.taxdata.sgst_value
    obj.taxamount =  this.taxdata.taxamount
    obj.total_amt = this.taxdata.total_amt
    obj.weight = this.weight
    obj.weight_type = this.weightType
   // console.log("dfjhvjsdf",this.weightType)

    if(localStorage.getItem('cartdata')){
      this.productcart = JSON.parse(localStorage.getItem('cartdata'))
    }else{
      this.productcart=[]
    }
    
    if(!isadded){
      this.productcart.push(obj)
    }else{
      this.productcart[index] = obj
    }
 //   console.log("productcart",this.productcart)
     localStorage.setItem("cartdata", JSON.stringify(this.productcart))
     
     this.productService.productAddUpdate$.next('added')
     this.productService.refreshData.next()
 
     this.toastrService.success("Item added to a Carts")
    //  this.router.navigateByUrl("/shop/cart")
    }
    })
   }
  
  
  }
  // Add to cart
  async addToCart(product: any) {
    product.quantity = this.counter || 1;
    const status = await this.productService.addToCart(product);
    if(status)
      this.router.navigate(['/shop/cart']);
  }

  // Buy Now
  async buyNow(product: any) {
    product.quantity = this.counter || 1;
    const status = await this.productService.addToCart(product);
    if(status)
      this.router.navigate(['/shop/checkout']);
  }

  // Add to Wishlist
  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
  }

  // Toggle Mobile Sidebar
  toggleMobileSidebar() {
    this.mobileSidebar = !this.mobileSidebar;
  }

 
}
