import { Component, OnInit, Input, ViewChild,PLATFORM_ID, Inject } from '@angular/core';
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-product-box-two',
  templateUrl: './product-box-two.component.html',
  styleUrls: ['./product-box-two.component.scss']
})
export class ProductBoxTwoComponent implements OnInit {

  @Input() product: any;
  @Input() currency: any = this.productService.Currency; // Default Currency 
  @Input() thumbnail: boolean = false; // Default False 
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;
  
  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;

  public ImageSrc : string
  isWishlisted: boolean = false;
  public discounted : any
  public amount_diff : any
  public diff_percent : any
  constructor(@Inject(PLATFORM_ID) private platformId: Object,private toastrService: ToastrService,private router:Router,private productService: ProductService) { }

  ngOnInit(): void {
    console.log("products__________________________________________________",this.product)
    if(this.product.imageUrl){
      //console.log(JSON.parse(this.product.imageUrl))
      let obj = this.product.imageUrl;
      console.log(typeof obj)
      if(typeof obj == "object"){
        var product = this.product.imageUrl = obj;
     // console.log(this.product.imageUrl);
      var resultArray = Object.keys(this.product.imageUrl).map(function(personNamedIndex){
        let person = product[personNamedIndex];
        // do something with person
        return person;
      });
      this.product.imageUrl = resultArray;
    this.ImageSrc = "https://pakizaonline.nyc3.digitaloceanspaces.com/"+resultArray[0];
    }
    }

    this.discounted = (((this.product.MRP - this.product.priceInclusive)/this.product.MRP)*100).toFixed()
    this.productService.userLoggedOutorIn$.subscribe((res)=>{
      if(res == 0){
        this.isWishlisted=false;
      }else{
        this.isWishlisted=true;
        if(localStorage.getItem("wishlisted")){
          var items = localStorage.getItem("wishlisted").split(',');
          console.log(items)
           if(items.includes(this.product.itemparentId.toString())){
             this.isWishlisted = true;
           }else{
             this.isWishlisted = false;
           }
        }else{
          this.productService.listWishlist({"user_id":localStorage.customerId}).subscribe(res=>{
            console.log(res);
            if(res['data'].item_ids){
               localStorage.setItem("wishlisted",res['data'].item_ids);
               var items = res['data'].item_ids.split(',');
               console.log(items)
                if(items.includes(this.product.itemparentId.toString())){
                  this.isWishlisted = true;
                }else{
                  this.isWishlisted = false;
                }
            }
          })
        }
      }
    });
    
    if(localStorage.isLoggedIn){
      if(localStorage.getItem("wishlisted")){
        var items = localStorage.getItem("wishlisted").split(',');
        console.log(items)
         if(items.includes(this.product.itemparentId.toString())){
           this.isWishlisted = true;
         }else{
           this.isWishlisted = false;
         }
      }else{
        this.productService.listWishlist({"user_id":localStorage.customerId}).subscribe(res=>{
          console.log(res);
          if(res['data'].item_ids){
             localStorage.setItem("wishlisted",res['data'].item_ids);
             var items = res['data'].item_ids.split(',');
             console.log(items)
              if(items.includes(this.product.itemparentId.toString())){
                this.isWishlisted = true;
              }else{
                this.isWishlisted = false;
              }
          }
        })
      }
    }

    this.productService.wishLishtUpdated$.subscribe(res=>{
      if(localStorage.isLoggedIn){
       // this.productService.listWishlist({"user_id":localStorage.customerId}).subscribe(res=>{
          console.log(res);
          if(localStorage.getItem("wishlisted")){
            localStorage.getItem("wishlisted");
             var items = localStorage.getItem("wishlisted").split(',');
             console.log(items)
             if(items.includes(this.product.itemparentId.toString())){
              this.isWishlisted = true;
            }else{
              this.isWishlisted = false;
            }
          }
        //})
      }
    })
    if(this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
  }

  // Get Product Color
  Color(variants) {
    const uniqColor = [];
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color)
      }
    }
    return uniqColor
  }

  // Change Variants
  ChangeVariants(color, product) {
    product.variants.map((item) => {
      if (item.color === color) {
        product.images.map((img) => {
          if (img.image_id === item.image_id) {
            this.ImageSrc = img.src;
          }
        })
      }
    })
  }

  // Change Variants Image
  ChangeVariantsImage(src) {
    this.ImageSrc = src;
  }

  addToCart(product: any) {
    this.productService.addToCart(product);
  }

  addToWishlist(product: any) {
    if (isPlatformBrowser(this.platformId)) {
      if(localStorage.isLoggedIn){
        console.log("router",this.router.url)
        console.log(product,product.itemvariants_id);
        let obj = {
          "user_id":localStorage.customerId,
          "itemvariants_id":product.itemparentId
        }
        this.productService.addProToWishlist(obj).subscribe(res=>{
          console.log(res);
          this.productService.listWishlist({"user_id":localStorage.customerId}).subscribe(res=>{
            console.log(res);
            if(res['data'].item_ids){
              localStorage.setItem("wishlisted",res['data'].item_ids);
               var items = res['data'].item_ids.split(',');
               console.log(items)
               if(items.includes(this.product.itemparentId.toString())){
                this.isWishlisted = true;
               }else{
                  this.isWishlisted = false;
               }
            }
            this.productService.wishLishtUpdated$.next(1)
          })
          
          this.toastrService.success(res['message']);
        });
      }else{
        localStorage.setItem("redirectBack",this.router.url)
        this.router.navigateByUrl('/pages/login');  
      }
    }
  }

}
