import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
// Menu
export interface Menu {
	path?: string;
	title?: string;
	type?: string;
	megaMenu?: boolean;
	image?: string;
	active?: boolean;
	badge?: boolean;
	badgeText?: string;
	children?: Menu[];
}

const httpOptions = {
	headers: new HttpHeaders({
	  Authorization: 'Basic WVdSdGFXND06WVdSdGFXND0='
	})
  };

@Injectable({
	providedIn: 'root'
})

export class NavService {

	constructor(private httpClient:HttpClient) { }

	public screenWidth: any;
	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			title: 'HOME', type: 'link', active: false, path: 'home/fashion',
		},
		// {
		// 	title: 'About us', type: 'link', active: false, path: 'pages/aboutus',
		// },
		// {
		// 	title: 'Men', type: 'link',path:'/shop/collection/division/men', megaMenu: true, active: false, children: [
		// 		{
		// 			title: 'TOPWEAR', type: 'sub', active: false, children: [
		// 				{ path: '/shop/collection/section/men/topwear', title: 'All', type: 'link' },
		// 				{ path: '/shop/collection/department/1/casual-shirts', title: 'Casual Shirts', type: 'link' },
		// 				{ path: '/shop/collection/department/1/formal-shirts', title: 'Formal Shirts', type: 'link' },
		// 				{ path: '/shop/collection/department/1/blazers-&-coats', title: 'Blazers & Coats', type: 'link' },
		// 				{ path: '/shop/collection/department/1/suits', title: 'Suits', type: 'link' },
		// 				{ path: '/shop/collection/department/1/t-shirts', title: 'T-Shirts', type: 'link' },
		// 				{ path: '/shop/collection/department/1/jackets', title: 'Jackets', type: 'link' },
		// 				{ path: '/shop/collection/department/1/sweaters', title: 'Sweaters', type: 'link' },
		// 				{ path: '/shop/collection/department/1/sweatshirts', title: 'Sweatshirts', type: 'link' }
		// 			]
		// 		},
		// 		{
		// 			title: 'Bottomwear', type: 'sub', active: false, children: [
		// 				{ path: '/shop/collection/section/men/bottomwear', title: 'All', type: 'link' },
		// 				{ path: '/shop/collection/department/2/casual-trousers', title: 'Casual Trousers', type: 'link' },
		// 				{ path: '/shop/collection/department/2/formal-trouser', title: 'Formal Trouser', type: 'link' },
		// 				{ path: '/shop/collection/department/2/jeans', title: 'Jeans', type: 'link' },
		// 				{ path: '/shop/collection/department/2/bermudas-&-three-fourths', title: 'Bermudas & Three-Fourths', type: 'link' },
		// 				{ path: '/shop/collection/department/2/track-pants-&-joggers', title: 'Track Pants & Joggers', type: 'link' }
		// 			]
		// 		},
		// 		{
		// 			title: 'Indian & Festive Wear', type: 'sub', active: false, children: [
		// 				{ path: '/shop/collection/section/men/indian-&-festive-wear', title: 'All', type: 'link' },
		// 				{ path: '/shop/collection/department/3/dupatta', title: 'Dupatta', type: 'link' },
		// 				{ path: '/shop/collection/department/3/kurta-&-kurta-sets', title: 'Kurta & Kurta Sets', type: 'link' },
		// 				{ path: '/shop/collection/department/3/sherwanis', title: 'Sherwanis', type: 'link' },
		// 				{ path: '/shop/collection/department/3/paijamas', title: 'Paijamas', type: 'link' },
		// 				{ path: '/shop/collection/department/3/nehru-jackets', title: 'Nehru Jackets', type: 'link' }
		// 			]
		// 		}
		// 	]
		// },
		{
			title: 'KURTIS', type: 'link',path:'/shop/collection/division/women', megaMenu: true, active: false, children: [
				{
					title: 'Ethenic Wear', type: 'sub', active: false, children: [
						{ path: '/shop/collection/section/women/ethenic-wear', title: 'All', type: 'link' },
						{ path: '/shop/collection/department/4/kurtas-&-kurties', title: 'Kurtas & Kurties', type: 'link' },
						{ path: '/shop/collection/department/4/lehenga-choli', title: 'Lehenga Choli', type: 'link' },
						{ path: '/shop/collection/department/4/salwars-&-patialia-sets', title: 'Salwars & Patialia Sets', type: 'link' },
						{ path: '/shop/collection/department/4/kurta-sets-&-salwar-suits', title: 'Kurta Sets & Salwar Suits', type: 'link' },
						{ path: '/shop/collection/department/4/dupattas-&-shawls', title: 'Dupattas & Shawls', type: 'link' },
						{ path: '/shop/collection/department/4/dress-materials', title: 'Dress Materials', type: 'link' },
						{ path: '/shop/collection/department/4/sarees-&-blouses', title: 'Sarees & Blouses', type: 'link' }
					]
				},  
				{
					title: 'Western Wear', type: 'sub', active: false, children: [
						{ path: '/shop/collection/section/women/western-wear', title: 'All', type: 'link' },
						{ path: '/shop/collection/department/5/capris', title: 'Capris', type: 'link' },
						{ path: '/shop/collection/department/5/trousers', title: 'Trousers', type: 'link' },
						{ path: '/shop/collection/department/5/jackets', title: 'Jackets', type: 'link' },
						{ path: '/shop/collection/department/5/jeans', title: 'Jeans', type: 'link' },
						{ path: '/shop/collection/department/5/shorts-&-skirts', title: 'Shorts & Skirts', type: 'link' },
						{ path: '/shop/collection/department/5/top-t-shirt-&-shirts', title: 'Top, T-Shirt & Shirts', type: 'link' },
						{path: '/shop/collection/department/5/sweatshirts', title: 'Sweatshirts', type: 'link'}
					]
				}
			]
		},
		// {
		// 	title: 'Kids', type: 'link', active: false,
		// },
		// {
		// 	title: 'Home & Living', type: 'link', active: false,
		// },
		// {
		// 	title: 'Essentials', type: 'link', active: false,
		// },
		// {
		// 	title: 'Offer', type: 'link', active: false,
		// },
		{
			title: 'BOTTOM WEAR', type: 'link', active: false,
		},
		{
			title: 'DUPATTA & STOLES', type: 'link', active: false, path: 'pages/contact',
		},
		{
			title: 'Offers', type: 'link', active: false, path: 'pages/contact',
		},
		

		// {
		// 	title: 'Shop', type: 'sub', active: false, children: [
		// 		{ path: '/shop/collection/left/sidebar', title: 'left-sidebar', type: 'link' },
		// 		{ path: '/shop/collection/right/sidebar', title: 'right-sidebar', type: 'link' },
		// 		{ path: '/shop/collection/no/sidebar', title: 'no-sidebar', type: 'link' }
		// 	]
		// },
		// {
		// 	title: 'Products', type: 'sub', active: false, children: [
		// 		{
		// 			title: 'sidebar', type: 'sub', active: false, children: [
		// 				{ path: '/shop/product/left/sidebar/trim-dress', title: 'left-sidebar', type: 'link' },
		// 				{ path: '/shop/product/right/sidebar/trim-dress', title: 'right-sidebar', type: 'link' },
		// 				{ path: '/shop/product/no/sidebar/trim-dress', title: 'no-sidebar', type: 'link' }
		// 			]
		// 		},
		// 		{ path: '/shop/product/three/column/trim-dress', title: 'three-column', type: 'link' },
		// 		{ path: '/shop/product/four/image/belted-dress', title: 'four-image', type: 'link' },
		// 		{ path: '/shop/product/bundle/trim-dress', title: 'bundle-product', type: 'link' },
		// 		{ path: '/shop/product/image/outside/trim-dress', title: 'image-outside', type: 'link' }
		// 	]
		// },
		
		// {
		// 	title: 'pages', type: 'sub', active: false, children: [
		// 		{
		// 			title: 'account', type: 'sub', active: false, children: [
		// 				{ path: '/pages/wishlist', title: 'wishlist', type: 'link' },
		// 				{ path: '/pages/cart', title: 'cart', type: 'link' },
		// 				{ path: '/pages/dashboard', title: 'dashboard', type: 'link' },
		// 				{ path: '/pages/login', title: 'login', type: 'link' },
		// 				{ path: '/pages/register', title: 'register', type: 'link' },
		// 				{ path: '/pages/contact', title: 'contact', type: 'link' },
		// 				{ path: '/pages/forget/password', title: 'forget-password', type: 'link' },
		// 				{ path: '/pages/profile', title: 'profile', type: 'link' },
		// 				{ path: '/pages/checkout', title: 'checkout', type: 'link' },
		// 			]
		// 		},
		// 		{ path: '/pages/aboutus', title: 'about-us', type: 'link' },
		// 		{ path: '/pages/search', title: 'search', type: 'link' },
		// 		{ path: '/pages/typography', title: 'typography', type: 'link', badge: true, badgeText: 'new' },
		// 		{ path: '/pages/review', title: 'review', type: 'link', badge: true, badgeText: 'new' },
		// 		{ path: '/pages/order/success', title: 'order-success', type: 'link' },
		// 			{ 
		// 				title: 'compare', type: 'sub', active: false, children: [
		// 					{ path: '/pages/compare/one', title: 'compare-1', type: 'link' },
		// 					{ path: '/pages/compare/two', title: 'compare-2', type: 'link', badge: true, badgeText: 'new' }
		// 				]
		// 			},
		// 		{ path: '/pages/collection', title: 'collection', type: 'link' },
		// 		{ path: '/pages/lookbook', title: 'lookbook', type: 'link' },
		// 		{ path: '/pages/404', title: '404', type: 'link' },
		// 		{ path: '/pages/comingsoon', title: 'coming-soon', type: 'link', badge: true, badgeText: 'new' },
		// 		{ path: '/pages/faq', title: 'faq', type: 'link' }
		// 	]
		// },
		// {
		// 	title: 'blogs', type: 'sub', active: false, children: [
		// 		{ path: '/pages/blog/left/sidebar', title: 'left-sidebar', type: 'link' },
		// 		{ path: '/pages/blog/right/sidebar', title: 'right-sidebar', type: 'link' },
		// 		{ path: '/pages/blog/no/sidebar', title: 'no-sidebar', type: 'link' },
		// 		{ path: '/pages/blog/details', title: 'blog-details', type: 'link' }
		// 	]
		// }
	];

	LEFTMENUITEMS: Menu[] = [
		{
			title: 'clothing', type: 'sub', megaMenu: true, active: false, children: [
			  {
				  title: 'mens fashion',  type: 'link', active: false, children: [
					  { path: '/home/fashion', title: 'sports wear',  type: 'link' },
					  { path: '/home/fashion', title: 'top',  type: 'link' },
					  { path: '/home/fashion', title: 'bottom',  type: 'link' },
					  { path: '/home/fashion', title: 'ethic wear',  type: 'link' },
					  { path: '/home/fashion', title: 'sports wear',  type: 'link' },
					  { path: '/home/fashion', title: 'shirts',  type: 'link' },
					  { path: '/home/fashion', title: 'bottom',  type: 'link' },
					  { path: '/home/fashion', title: 'ethic wear',  type: 'link' },
					  { path: '/home/fashion', title: 'sports wear',  type: 'link' }
				  ]
			  },
			  {
				  title: 'women fashion',  type: 'link', active: false, children: [
					  { path: '/home/fashion', title: 'dresses',  type: 'link' },
					  { path: '/home/fashion', title: 'skirts',  type: 'link' },
					  { path: '/home/fashion', title: 'westarn wear',  type: 'link' },
					  { path: '/home/fashion', title: 'ethic wear',  type: 'link' },
					  { path: '/home/fashion', title: 'bottom',  type: 'link' },
					  { path: '/home/fashion', title: 'ethic wear',  type: 'link' },
					  { path: '/home/fashion', title: 'sports wear',  type: 'link' },
					  { path: '/home/fashion', title: 'sports wear',  type: 'link' },
					  { path: '/home/fashion', title: 'bottom wear',  type: 'link' }
				  ]
			  },
			]
		},
		{
			title: 'bags', type: 'sub', active: false, children: [
			  { path: '/home/fashion', title: 'shopper bags', type: 'link' },
			  { path: '/home/fashion', title: 'laptop bags', type: 'link' },
			  { path: '/home/fashion', title: 'clutches', type: 'link' },
			  {
				  path: '/home/fashion', title: 'purses', type: 'link', active: false, children: [
					  { path: '/home/fashion', title: 'purses',  type: 'link' },
					  { path: '/home/fashion', title: 'wallets',  type: 'link' },
					  { path: '/home/fashion', title: 'leathers',  type: 'link' },
					  { path: '/home/fashion', title: 'satchels',  type: 'link' }
				  ]
			  },
			]
		},
		{
			title: 'footwear', type: 'sub', active: false, children: [
			  { path: '/home/fashion', title: 'sport shoes', type: 'link' },
			  { path: '/home/fashion', title: 'formal shoes', type: 'link' },
			  { path: '/home/fashion', title: 'casual shoes', type: 'link' }
			]
		},
		{
			path: '/home/fashion', title: 'watches', type: 'link'
		},
		{
			title: 'Accessories', type: 'sub', active: false, children: [
			  { path: '/home/fashion', title: 'fashion jewellery', type: 'link' },
			  { path: '/home/fashion', title: 'caps and hats', type: 'link' },
			  { path: '/home/fashion', title: 'precious jewellery', type: 'link' },
			  {
				  path: '/home/fashion', title: 'more..', type: 'link', active: false, children: [
					  { path: '/home/fashion', title: 'necklaces',  type: 'link' },
					  { path: '/home/fashion', title: 'earrings',  type: 'link' },
					  { path: '/home/fashion', title: 'rings & wrist wear',  type: 'link' },
					  {
						  path: '/home/fashion', title: 'more...',  type: 'link', active: false, children: [
							  { path: '/home/fashion', title: 'ties',  type: 'link' },
							  { path: '/home/fashion', title: 'cufflinks',  type: 'link' },
							  { path: '/home/fashion', title: 'pockets squares',  type: 'link' },
							  { path: '/home/fashion', title: 'helmets',  type: 'link' },
							  { path: '/home/fashion', title: 'scarves',  type: 'link' },
							  {
								  path: '/home/fashion', title: 'more...',  type: 'link', active: false, children: [
									  { path: '/home/fashion', title: 'accessory gift sets',  type: 'link' },
									  { path: '/home/fashion', title: 'travel accessories',  type: 'link' },
									  { path: '/home/fashion', title: 'phone cases',  type: 'link' }
								  ]
							  },
						]
					  }
				  ]
			  },
			]
		},
		{
			path: '/home/fashion', title: 'house of design', type: 'link'
		},
		{
			title: 'beauty & personal care', type: 'sub', active: false, children: [
			  { path: '/home/fashion', title: 'makeup', type: 'link' },
			  { path: '/home/fashion', title: 'skincare', type: 'link' },
			  { path: '/home/fashion', title: 'premium beaty', type: 'link' },
			  {
				  path: '/home/fashion', title: 'more..', type: 'link', active: false, children: [
					  { path: '/home/fashion', title: 'fragrances',  type: 'link' },
					  { path: '/home/fashion', title: 'luxury beauty',  type: 'link' },
					  { path: '/home/fashion', title: 'hair care',  type: 'link' },
					  { path: '/home/fashion', title: 'tools & brushes',  type: 'link' }
				  ]
			  },
			]
		},
		{
			path: '/home/fashion', title: 'home & decor', type: 'link'
		},
		{
			path: '/home/fashion', title: 'kitchen', type: 'link'
		},
	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);

	public getMenus(){

		return this.httpClient.get('https://prabhujipurefood.com/administration/api/navigations',httpOptions)
	}
    
	
}
