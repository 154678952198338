<div class="search_b">
    <div [ngClass]="showSearch?'filter-search-filterSearchBox filter-search-expanded':'filter-search-filterSearchBox'"><input type="text" (keyup)="filterCategory($event)"  [ngClass]="showSearch?'filter-search-inputBox filter-search-block':'filter-search-inputBox filter-search-hidden'" class="" placeholder="Search for Category"><i (click)="showSearchBox()" class="fas fa-search"></i></div>
  </div>  
  <div class="collection-collapse-block" [class.open]="collapse" *ngIf="section[0] && section[0].length">
   
    <h3 class="collapse-block-title">Category</h3>
      
      <div class="collection-collapse-block-content">
        <div class="collection-brand-filter">
          <div class="custom-control custom-checkbox collection-filter-checkbox" 
              *ngFor="let category of section[0]; let i = index">
              <input (change)="appliedFilter($event)" [checked]="checked(category.dept_id)" type="checkbox" [value]="category.dept_id" [id]="category.dept_id" class="custom-control-input" />
              <label class="custom-control-label" for="{{ category.dept_id }}">{{ category.dept_name }}</label>
          </div>
        </div>
      </div>
  </div>
  
  <div class="collection-collapse-block min-hight" *ngIf="section[0] && section[0].length==0">
    <div class="collection-collapse-block-content">
    &nbsp;
  </div>
  </div>
<!-- End Categories List -->