<!--footer section -->

<section class="newsLetter-container custom-space">
   <div class="container">
      <div class="row">
         <div class="col-lg-12">
            <div class="newsLetter-block">
               <h5>Subscribe and get access to our exclusive offers & deals</h5>
               <div class="newsLetter-inputBox">
                  <input type="text" class="form-control" placeholder="Enter your email">
                  <button type="submit">Subscribe</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>

<section class="bannerCat">
   <div class="container">
      <div class="row">
         <div class="col-lg-4 col-md-4 col-12">
            <div class="bannerCatBox">
               <img src="assets/images/icon/ExpressDelivery.svg">
               <h6>Super Express Delivery</h6>
            </div>
         </div>

         <div class="col-lg-4 col-md-4 col-12 centerCol">
            <div class="bannerCatBox">
               <img src="assets/images/icon/EasyShipping.svg">
               <h6>Easy Shipping</h6>
            </div>
         </div>

         <div class="col-lg-4 col-md-4 col-12 d-flex justify-content-center">
            <div class="bannerCatBox">
               <img src="assets/images/icon/GuaranteedTaste.svg">
               <h6>100% Authentic</h6>
            </div>
         </div>
      </div>
   </div>
</section>

<div class="footerDevider"></div>

<footer [class]="class" id="footer">
   <div class="container">
      <div class="row">
         <div class="col-lg-4 col-md-4 col-12">
            <div class="aboutFooter">
               <a routerLink="/home" class="BrandLogos">
                  <img [src]="themeLogo" class="img-fluid" alt="logo">
               </a>
            </div>
         </div>

         <div class="col-lg-8 col-md-8 col-12">
            <div class="row">
               <div class="col-lg-4 col-md-4 col-12">
                  <div class="FooterCol">
                     <h5 class="FooterCol-Title">Quick Links</h5>
                     <ul class="FooterLinks">
                        <li><a href="javascript:void(0)" routerLink="/pages/aboutus">About us</a></li>
                        <li><a href="javascript:void(0)" routerLink="/pages/contact">Contact us</a></li>
                        <li><a href="javascript:void(0)">Track your order</a></li>
                        <li><a href="javascript:void(0)">Pincode list</a></li>
                     </ul>
                  </div>
               </div>

               <div class="col-lg-4 col-md-4 col-12">
                  <div class="FooterCol">
                     <h5 class="FooterCol-Title">Information</h5>
                     <ul class="FooterLinks">
                        <li><a href="javascript:void(0)">Terms</a></li>
                        <li><a href="javascript:void(0)">Privacy</a></li>
                        <li><a href="javascript:void(0)">Returns & Refunds</a></li>
                        <li><a href="javascript:void(0)">Brand history</a></li>
                     </ul>
                  </div>
               </div>

               <div class="col-lg-4 col-md-4 col-12">
                  <div class="FooterCol">
                     <h5 class="FooterCol-Title">Got Questions?</h5>
                     <h6>Mon to Sat 00:00 - 00:00 PM IST</h6>

                     <h5 class="FooterCol-Title">Payment Method</h5>

                     <div class="paymentMethod">
                        <img src="assets/images/icon/master-card.svg"/>
                        <img src="assets/images/icon/visa-card.svg"/>
                     </div>

                     <ul class="FooterSocials">
                        <li>
                           <a href="javascript:void(0)">
                              <img src="assets/images/social-icon/facebook.svg"/>
                           </a>
                        </li>
                        <li>
                           <a href="javascript:void(0)">
                              <img src="assets/images/social-icon/insta.svg"/>
                           </a>
                        </li>
                        <li>
                           <a href="javascript:void(0)">
                              <img src="assets/images/social-icon/twiter.svg"/>
                           </a>
                        </li>
                        <li>
                           <a href="javascript:void(0)">
                              <img src="assets/images/social-icon/linkedin.svg"/>
                           </a>
                        </li>

                        <li>
                           <a href="javascript:void(0)">
                              <img src="assets/images/social-icon/youtube.svg"/>
                           </a>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</footer>

<section class="FooterBrand">
   <img src="assets/images/footerBrand-bg.png">
</section>
<!--footer section end -->