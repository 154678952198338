<app-breadcrumb [title]="product?.title" class="Product-Details-Breadcrumb" [catname]="catname"
   [breadcrumb]="'Product'"></app-breadcrumb>

<section class="ProductDetails-Container custom-space">
   <div class="container">
      <div class="row align-items-center">
         <div class="col-lg-6">
            <div class="mainProduct-ThumbnailBox">
               <div class="activeProduct">
                  <!-- <owl-carousel-o [options]="ProductDetailsMainSliderConfig" #owlCar class="ProductCarousel">
                     <ng-container *ngFor="let image of productdetails.product_image; index as i">
                        <ng-template carouselSlide [id]="i"> -->
                           <div class="ThumbnailImageBox">
                              <img [src]="productdetails.master_image" onError="this.src='https://via.placeholder.com/150'" alt="prabhujipurefood" class="img-fluid">
                           </div>
                        <!-- </ng-template>
                     </ng-container>
                  </owl-carousel-o> -->
               </div>

               <!-- <div class="sliderNav">
                  <owl-carousel-o [options]="ProductDetailsThumbConfig">
                     <ng-container *ngFor="let image of productdetails.product_image; index as i">
                        <ng-template carouselSlide [id]="i">
                           <div >
                              <div >
                                 <img [src]="productdetails.master_image" onError="this.src='https://via.placeholder.com/150'" alt="prabhujipurefood" class="img-fluid">
                              </div>
                           </div>
                        </ng-template>
                     </ng-container>
                  </owl-carousel-o>
               </div> -->
            </div>
         </div>

         <div class="col-lg-6">
            <div class="product-detailsOverview">
               <h2 class="productTitle">{{productdetails.title}}</h2>
               <div class="priceSku">
                  <div class="productPrice">
                     <h3 class="saleprice">Rs. {{offer_price}}/-</h3>

                     <h3 *ngIf="offer_price!=main_price" class="mainprice">
                        <span></span>
                     </h3>
                  </div>
                  <div class="skuTags">
                     <p class="skus">{{productdetails.sku}}</p>
                     <p *ngIf="product_stock > 0" class="stock">
                        <span class="stock_dots"></span>IN STOCK
                     </p>

                     <p *ngIf="product_stock === 0" class="stock outofStock">
                        <span class="stock_dots"></span>OUT OF STOCK
                     </p>
                  </div>
               </div>

               <div class="weightDetails">
                  <p>Net Weight</p>
                  <div class="selectBox">
                     <select *ngIf="productdetails.product_variant" (change)="weightChange($event)">
                        <option *ngFor="let product of productdetails.product_variant;let i=index;"
                           value="{{i}}">
                           {{product.weight}}{{product.weight_type}}
                        </option>
                     </select>
                  </div>
               </div>

               <div class="productBtns_groups">
                  <div class="qty-box bg-secondry">
                     <h5>Qty.</h5>
                     <div class="qtyInput-group">
                        <button type="button" class="btn quantity-left-minus" data-type="minus" (click)="decrement()">
                           <i class="material-icons">remove</i>
                        </button>
                        
                        <input type="text" name="quantity" class="form-control input-number" [value]="counter" disabled />
                        
                        <button type="button" class="btn quantity-right-plus" data-type="plus" (click)="increment()">
                           <i class="material-icons">add</i>
                        </button>
                     </div>
                  </div>
      
                  <button type="submit" [disabled]="product_stock === 0" class="btn ThemeBtn addCart-btn" (click)="addtocart(productdetails)">Add to cart</button>
                  
                  <button type="submit" class="btn ThemeBtn wishList-btn bg-secondry" (click)="addtowish(productdetails)">Wishlist</button>
               </div>

               <div class="deliveryOption">
                  <label for="">Delivery Options</label>
                  <div class="pinCode-input">
                     <input type="text" name="pincode" placeholder="Enter pincode" class="form-control"/>
                     <button>Check</button>
                  </div>
               </div>
               
               <div class="productSocial">
                  <share-buttons [theme]="'material-dark'"
                     [include]="['facebook','pinterest','twitter','whatsapp']" [show]="4" [size]="-4"
                     [url]="url" [autoSetMeta]="false">
                  </share-buttons>
               </div>
            </div>
         </div>
      </div>
   </div>
   <!-- <app-related-product></app-related-product> -->
</section>

<section class="bannerCat">
   <div class="container">
      <div class="row">
         <div class="col-lg-4 col-md-4 col-12">
            <div class="bannerCatBox">
               <img src="assets/images/icon/SanitisedEnvironment.svg">
               <h6>Sanitised Environment</h6>
            </div>
         </div>

         <div class="col-lg-4 col-md-4 col-12 centerCol">
            <div class="bannerCatBox">
               <img src="assets/images/icon/HygienicallyProcessed.svg">
               <h6>Hygienically Processed</h6>
            </div>
         </div>

         <div class="col-lg-4 col-md-4 col-12 rightCol">
            <div class="bannerCatBox">
               <img src="assets/images/icon/GuaranteedTaste.svg">
               <h6>Guaranteed with Taste</h6>
            </div>
         </div>
      </div>
   </div>
</section>