<div class="main-navbar">
   <div id="mainnav">
      <ul class="nav-menu" [class.opennav]="navServices?.mainMenuToggle">
         <li><a [routerLink]="'/'" class="nav-link">Home</a></li>
         <li class="dropdown-onHover" *ngFor="let cat of category">
            <a class="nav-link dropdown-toggle" (click)="getDataByCat(cat)">{{cat.category_name}}</a>
            <ul class="dropdown-menu">
               <li *ngFor="let item of cat.subCategory">
                  <a (click)="getDataBySubCat(item.sub_cat_id,item.slug)" class="dropdown-item">{{item.name}}</a>
               </li>
            </ul>
         </li>
      </ul>
   </div>
</div>