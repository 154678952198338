import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input() title : string;
  @Input() breadcrumb : any;
  @Input() id:any;
  @Input() catname:any;
  
 // catname: any;
  categories: any=[];

  constructor(public route:ActivatedRoute) {
  }

  ngOnInit() : void { 
    console.log(this.id)
    console.log(this.breadcrumb)
    this.route.queryParams.subscribe(params => {
   this.categories= JSON.parse(localStorage.getItem("categories"));
  //  this.categories.forEach(element => {
  //    if(element.id==this.id){
  //      console.log("category.........",element.category_name)
  //      this.catname = element.category_name
  //    }
  //  });
  })
    // if(this.id==this.breadcrumb.id){
    //   this.catname = this.breadcrumb.category_name
    // }
   }

}
