<header class="sticky" [class.fixed]="stick && sticky">
   <div class="container">
      <div class="row">
         <div class="col-lg-12">
            <div class="main-menu">
               <a routerLink="/home" class="BrandLogos">
                  <img [src]="themeLogo" class="img-fluid" alt="logo">
               </a>

               <div class="rightItem">
                  <div class="menu-right left-menu">
                     <app-menu></app-menu>
                     <app-settings></app-settings>
                  </div>

                  <!-- Mobile Nav -->
                  <div class="menu-left">
                     <div class="mobile_opens"> 
                        <app-left-menu></app-left-menu>
                     </div>
                  </div>
                  <!-- Mobile Nav -->
               </div>
            </div>
         </div>
      </div>
   </div>
</header>